import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import getCommonStyles from '../../../../theme/commonStyles';
import { otherColorSets, otherContrastColorSets } from '../../../../theme/palette';
import dimensions from '../../../../theme/dimensions';

const PORTAL_WIDTH = dimensions.gptPortal.width;
const CHAT_WIDTH = PORTAL_WIDTH - 200;

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    gptChatItem: {
      flex: 1,
      marginLeft: theme.spacing(1),
      padding: `${theme.spacing(0)} ${theme.spacing(7)} ${theme.spacing(0)} ${theme.spacing(1)}`,
      '&.error': {
        fontStyle: 'italic',
        color: theme.palette.error.main,
      },
      fontWeight: '300 !important',
      maxWidth: CHAT_WIDTH,
      '&.task': {
        paddingTop: theme.spacing(2),
      },
      '&.steps': {
        paddingTop: theme.spacing(2),
      },
    },
    gptChatItemStep: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      '& > div:first-child': {
        marginTop: theme.spacing(0.25),
        flex: 0,
        flexBasis: 50,
        '& > p': {
          marginTop: -4,
          fontSize: 10,
          textAlign: 'center',
        },
      },
      '& > div:not(:first-child)': {
        flex: 1,
        marginLeft: theme.spacing(1),
        '& > p': {
          fontSize: 12,
          maxWidth: 700,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      '& .MuiTypography-root': {
        color: `${otherContrastColorSets[2]} !important`,
      },
    },
    gptChatStepSvg: {
      height: theme.spacing(2),
      '& #svgThroughtIcon': {
        stroke: `${otherContrastColorSets[2]} !important`,
      },
      '& #svgInputIcon': {
        fill: `${otherContrastColorSets[2]} !important`,
      },
      '& #svgOutputIcon': {
        fill: `${otherContrastColorSets[2]} !important`,
      },
      '& .path': {
        stroke: `${otherContrastColorSets[2]} !important`,
      },
    },
    gptChatStepLine: {
      height: theme.spacing(2),
      width: 1,
      borderLeft: '1px solid transparent',
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(0.25),
    },
    taskInput: {
      display: 'flex',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      '& > div:first-child': {
        flex: 0,
        flexBasis: theme.spacing(2),
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: '50%',
        width: theme.spacing(2),
        height: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.928rem',
        fontWeight: 'normal',
        color: otherColorSets[2],
        marginTop: theme.spacing(0.25),
        marginRight: theme.spacing(1),
      },
      '& > div:last-child': {
        display: 'flex',
        flex: 1,
        paddingLeft: theme.spacing(1),
        color: otherColorSets[2],
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        fontSize: '1.0714rem',
        fontWeight: 'normal',
        paddingBottom: theme.spacing(3),
      },
    },
    minStepProgress: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      textAlign: 'center',
    },
  });
};

export default styles;
