import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';
import getCommonStyles from '../../../../theme/commonStyles';
import {
  otherBorderColorSets,
  otherContrastColorSets,
} from '../../../../theme/palette';

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
    },
    codeBlockHeader: {
      padding: `4px ${theme.spacing(2)}`,
      background: 'rgb(250, 250, 250)',
      borderBottom: `1px solid ${theme.palette.grey[50]}`,
      '& + div': {
        marginTop: '0 !important',
      },
      '& .title': {
        fontWeight: 'bold',
      },
    },
    copyCodeLink: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 4,
      color: `${theme.palette.grey[500]} !important`,
      textDecorationColor: `${theme.palette.grey[500]} !important`,
      textDecoration: 'none !important',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline !important',
      },
    },
    requestingChart: {
      height: 378,
      border: '1px solid rgb(224, 224, 224)',
      borderRadius: theme.spacing(1.5),
      display: 'flex',
      marginTop: 45,
      background: theme.palette.common.white,
      alignItems: 'center',
      justifyContent: 'center',
      '& svg > *': {
        color: `${otherContrastColorSets[2]} !important`,
      },
    },
    contentModeButtons: {
      backgroundColor: '#e7e7f6 !important',
      fontSize: '0.85rem !important',
      color: `${otherContrastColorSets[2]} !important`,
      whiteSpace: 'nowrap',
      '&:not(.active):hover': {
        backgroundColor: `${Color('#e7e7f6').darken(0.05).toString()} !important`,
      },
      '&.active': {
        backgroundColor: `${otherBorderColorSets[2]} !important`,
        color: `${theme.palette.common.white} !important`,
      },
    },
    previewFrame: {
      border: '1px solid rgb(224, 224, 224)',
      borderRadius: theme.spacing(1.5),
    },
    loadingBlock: {
      background: 'rgb(250, 250, 250)',
      height: 300,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });
};

export default styles;
