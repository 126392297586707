import { bindActionCreators, Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';

import { ApplicationState } from '../../../types';

import {
  toggleChatItemStep,
} from '../actions';

import GptChatStepsView from './GptChatStepsView';

function mapStateToProps(state: ApplicationState) {
  return ({
    ...state.gpt,
    ...state.session,
  });
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    toggleChatItemStep,
  },
  dispatch,
);

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GptChatStepsView);
