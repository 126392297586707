import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Divider, Typography } from '@mui/material';
import classNames from 'classnames';

import { ReactComponent as AgentIcon } from '../../../../assets/svg/agentIcon.svg';
import { ReactComponent as ObjectiveIcon } from '../../../../assets/svg/objectiveIcon.svg';
import { ReactComponent as TaskIcon } from '../../../../assets/svg/taskIcon.svg';
import { ReactComponent as ThoughtIcon } from '../../../../assets/svg/thoughtIcon.svg';
import { ReactComponent as ToolIcon } from '../../../../assets/svg/toolIcon.svg';
import { ReactComponent as InputIcon } from '../../../../assets/svg/inputIcon.svg';
import { ReactComponent as OutputIcon } from '../../../../assets/svg/outputIcon.svg';

import { ChatStepItemInfo, GptChatItem } from '../types';
import { PropsFromRedux } from './container';
import GptAnswerView from '../gptAnswerView';
import styles from './GptChatStepsView.styles';
import { GptLoadProgress } from '../../../../components';

const useStyles = makeStyles(styles);

interface Props extends PropsFromRedux {
  chatItem: GptChatItem;
  chatItemIndex: number;
}

const GptChatStepsView: React.FC<Props> = ({
  chats,
  chatItem,
  chatItemIndex,
  chatState,
  userExtraInfo,
}: Props) => {
  const classes = useStyles();

  const minSteps = userExtraInfo?.results?.at(0)?.minSteps;
  const shouldShowMinSteps = (minSteps && chatState === 'streaming' && !chatItem.hasStepOutput);

  const renderStepIcon = (stepItemInfo: ChatStepItemInfo) => {
    if (stepItemInfo.name === 'Agent') {
      return (
        <AgentIcon className={classes.gptChatStepSvg} />
      );
    }

    if (stepItemInfo.name === 'Objective') {
      return (
        <ObjectiveIcon className={classes.gptChatStepSvg} />
      );
    }

    if (stepItemInfo.name === 'Task') {
      return (
        <TaskIcon className={classes.gptChatStepSvg} />
      );
    }

    if (stepItemInfo.name === 'Thought') {
      return (
        <ThoughtIcon className={classes.gptChatStepSvg} />
      );
    }

    if (stepItemInfo.name === 'Action Input') {
      return (
        <InputIcon className={classes.gptChatStepSvg} />
      );
    }

    if (stepItemInfo.name === 'Output') {
      return (
        <OutputIcon className={classes.gptChatStepSvg} />
      );
    }

    return (
      <ToolIcon className={classes.gptChatStepSvg} />
    );
  };

  const getStepItemCommonName = (stepItemInfo: ChatStepItemInfo) => {
    if (stepItemInfo.name === 'Agent') {
      return 'Agent';
    }

    if (stepItemInfo.name === 'Objective') {
      return 'Objective';
    }

    if (stepItemInfo.name === 'Task') {
      return 'Task';
    }

    if (stepItemInfo.name === 'Thought') {
      return 'Thought';
    }

    if (stepItemInfo.name === 'Action Input') {
      return 'Input';
    }

    if (stepItemInfo.name === 'Output') {
      return 'Output';
    }

    return 'Tool';
  };

  return !shouldShowMinSteps ? (
    <>
      {chatItem.isShowingSteps
      && (chatItem.steps || []).some((step) => (step.items || []).length)
      && (chatItem.steps || [])
        .filter((step) => step.items.length > 0 && !step.isPlanExecuteTask)
        .map((step, stepIndex) => (
          <Box
            key={stepIndex}
            className={
              classNames(
                classes.gptChatItem,
                'tertiary',
                chatItem.type,
                'steps',
              )
            }
            marginBottom={1}
          >
            {chatItem.mode === 'task' && (
              <div className={classes.taskInput}>
                <div>
                  {Math.round((chatItemIndex - 1) / 2)}
                </div>
                <div>
                  {chats[chatItemIndex - 1].content}
                </div>
              </div>
            )}

            {step.items
              .map((stepItem) => ({
                ...stepItem,
                value: stepItem.value.replaceAll('```', '\n```'),
              }))
              .map((stepItem, i) => (
                <div key={i} className={classes.gptChatItemStep}>
                  <div>
                    {renderStepIcon(stepItem)}
                    <Typography variant="body2">
                      {getStepItemCommonName(stepItem)}
                    </Typography>
                    <div className={classes.gptChatStepLine} />
                  </div>
                  <div>
                    <GptAnswerView
                      answerMarkdown={stepItem.value}
                      stepContent
                    />
                  </div>
                </div>
              ))}
            <Divider />
          </Box>
        ))}
    </>
  ) : (
    <>
      {(chatItem.steps || []).some((step) => (step.items || []).length) && (
        <div className={classes.minStepProgress}>
          <GptLoadProgress />
          <Typography variant="body2" marginTop={1}>
            Gathering Information
          </Typography>
          <Box marginTop={2}>
            <Divider />
          </Box>
        </div>
      )}
    </>
  );
};

export default GptChatStepsView;
