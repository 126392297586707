/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  createContext,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Portal,
  Typography,
} from '@mui/material';
import ReactMarkdown, { ExtraProps, Options as ReactMarkdownOptions } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

import classNames from 'classnames';

import { useResizeDetector } from 'react-resize-detector';

import { QuickChartInfo, QuickChartUpdateInfo } from '../../../../core/types/quickChart';
import { CalloutBubble } from '../../../../components';
import { ReactComponent as ShowGraphIcon } from '../../../../assets/svg/showGraphIcon.svg';
import { ReactComponent as ShowMeIcon } from '../../../../assets/svg/showMeIcon.svg';

import refineTableInAnswer, { translateSourceTableToMarkdown } from '../gptUtils';
import {
  extractTableMarkdown,
  getGlossaryLinkMarkdown,
  getHighlightedSourceMarkdown,
  getInlineChartMarkdown,
} from '../../../../core/utils/stringUtils';
import { ChatStates, SourceInfo } from '../types';
import ContentView from '../contentView';

import { PropsFromRedux } from './container';
import styles from './GptAnswerView.styles';
import QuickChart from '../../../../components/quickChart';
import {
  getTableHeaders,
  getTableRows,
} from '../../../../core/utils/uxUtils';
import { RelationEntity } from '../../relationChart/types';
import NetworkState from '../../../../core/types/network';
import DataTable from '../../../../components/dataTable/DataTable';
import ImageLoader from '../../../../components/imageLoader/ImageLoader';
import { useCommonClasses } from '../../../../theme/commonStyles';
import CodeBlockView from '../codeBlockView';

interface Props extends PropsFromRedux {
  answerViewId?: string;
  answerMarkdown?: string;
  answerSources?: SourceInfo[];
  answerMode?: 'search';
  highlights?: string[];
  onTyping?: () => void;
  omitEmbededSources?: boolean;
  stepContent?: boolean;
  chatIndex?: number;
  inlineCharts?: (QuickChartInfo | undefined)[][];
  relationEntities?: RelationEntity[];
  isProcessing?: boolean;
  tableSources?: string[];
}

interface AnswerMarkdownOptions extends ReactMarkdownOptions {
  inlineCharts?: (QuickChartInfo | undefined)[][];
  width?: number;
  chartNetworkState?: NetworkState;
  relationEntities?: RelationEntity[];
}

interface AnswerContextData {
  processedAnswer?: string;
  isStepContent?: boolean;
  chatState?: ChatStates;
}

const useStyles = makeStyles(styles);
const GLOSSARY_BUBBLE_TIMEOUT = 1000;
const SOURCE_BUBBLE_TIMEOUT = 0;
const ENTITY_BUBBLE_TIMEOUT = 0;

const AnswerContext = createContext<AnswerContextData>({});

function getEntityLinkMarkdown(text: string, relationEntities: RelationEntity[]) {
  if (relationEntities.length === 0 || !text) {
    return text;
  }

  const entityInfos = relationEntities
    .filter((entity) => (
      !!entity.label
      && (
        !!entity.summary
        || !!entity.infobox
      )
    ))
    .map((entity) => ({
      label: entity.label!,
      entity,
    }));

  const entityLabels = entityInfos.map((info) => info.label);
  const entityDataLookup = entityInfos.reduce((lookup, info) => ({
    ...lookup,
    [info.label]: info.entity,
  }), {} as Record<string, RelationEntity>);

  const lines = text.split('\n');
  let isInCodeBlock = false;
  return lines.reduce((final, line) => {
    if (line.trim().startsWith('```')) {
      isInCodeBlock = !isInCodeBlock;
    }

    if (isInCodeBlock) {
      return `${final}${line}\n`;
    }

    const processed = line.replace(
      new RegExp(String.raw`\[[^\][]*]\([^()]*\)|\b(${entityLabels.join('|')})\b`, 'gi'),
      (m, g: string, idx: number) => {
        const shouldReplace = line.at(idx - 1) !== '\u200B'
          && line.at(idx - 1) !== '`'
          && line.at(idx + (g?.length || 0)) !== '`';

        return g && entityDataLookup[g] && shouldReplace
          ? `[${g}](/entity-${entityDataLookup[g].entityType}#${encodeURIComponent(g)})`
          : m;
      },
    );

    return `${final}${processed}\n`;
  }, '');
}

const encodeEntities = (text: string, relationEntities: RelationEntity[]) => {
  if (relationEntities.length === 0 || !text) {
    return text;
  }

  const entityInfos = relationEntities
    .filter((entity) => (
      !!entity.label
      && (
        !!entity.summary
        || !!entity.infobox
      )
    ))
    .map((entity) => ({
      label: entity.label!,
      entity,
    }));

  const entityLabels = entityInfos.map((info) => info.label);

  if (entityLabels.length === 0) {
    return text;
  }

  return text.replace(
    new RegExp(String.raw`\b(${entityLabels.join('|')})\b`, 'gi'),
    (m) => `\u200B${m}`,
  );
};

const preprocessLaTeX = (content: string, relationEntities?: RelationEntity[]) => {
  const escapedDollarSign = content.replace(/(?<!\\)\$/gs, '\\$');

  // Replace block-level LaTeX delimiters \[ \] with $$ $$
  const blockProcessedContent = escapedDollarSign.replace(
    /\\\[(.*?)\\\]/gs,
    (_, equation) => `$$${String(encodeEntities(String(equation), relationEntities || []))}$$\n`,
  );
  // Replace inline LaTeX delimiters \( \) with $ $
  const inlineProcessedContent = blockProcessedContent.replace(
    /\\\((.*?)\\\)/gs,
    (_, equation) => `$${String(encodeEntities(String(equation), relationEntities || []))}$\n`,
  );

  return inlineProcessedContent;
};

const GptMarkdown = (props: AnswerMarkdownOptions) => (
  <div style={{ width: props.width }}>
    <ReactMarkdown
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      children={String(props.children)}
    />
  </div>
);

const MemoGptMarkdown = memo(
  GptMarkdown,
  (a: AnswerMarkdownOptions, b: AnswerMarkdownOptions) => (
    a.children === b.children
    && JSON.stringify(a.inlineCharts) === JSON.stringify(b.inlineCharts)
    && a.width === b.width
    && a.chartNetworkState === b.chartNetworkState
    && a.className === b.className
  ),
);

const MemoCodeBlockView = memo(
  CodeBlockView,
  (a, b) => (
    a.code === b.code
    && a.language === b.language
  ),
);

const CodeComponent = (
  props: React.ClassAttributes<HTMLElement> & React.HTMLAttributes<HTMLElement> & ExtraProps,
) => {
  const {
    children,
    className,
    node,
    ...rest
  } = props;

  const answerContext = useContext(AnswerContext);
  const processedAnswer = answerContext.processedAnswer || '';

  const classes = useStyles();
  const offset = props.node?.position?.end.offset;
  const codeAtOffset = processedAnswer.substring((offset || 0) - 3);
  const hasCodeEnded = codeAtOffset.includes('```\n');

  const match = /language-(\w+)/.exec(className || '');
  const code = String(children).replace(/\n$/, '');

  return match ? (
    <>
      <MemoCodeBlockView
        code={code}
        codeRendererProps={rest}
        isStepContent={answerContext.isStepContent || false}
        title={match[0]}
        language={match[1]}
        allowPreview={answerContext.chatState !== 'streaming'}
        hasCodeEnded={hasCodeEnded}
      />
    </>
  ) : (
    <code {...rest} className={classNames(className, classes.generalCodeBlock)}>
      {children}
    </code>
  );
};

const GptAnswerView: React.FC<Props> = ({
  answerViewId,
  answerMarkdown,
  answerSources,
  answerMode,
  highlights,
  setSearchKeyword,
  toggleGptChat,
  openGlossaryBubble,
  omitEmbededSources,
  openSourceBubble,
  closeSourceBubble,
  sourceBubble,
  stepContent,
  inlineCharts,
  chatState,
  chatIndex,
  duplicateChatItemChart,
  removeChatItemChart,
  requestUpdateChatItemChart,
  undoChatItemChartChange,
  relationEntities,
  networkStates: {
    chatItemChartUpdateRequest,
    tableToChartRequest,
  },
  openEntityBubble,
  closeEntityBubble,
  isProcessing,
  tableSources,
  requestTableToChart,
  toggleSourceCitation,
  toggleChatItemTable,
  setSelectedSideBarTab,
}: Props) => {
  const classes = useStyles();
  const commonCss = useCommonClasses();

  const glossaryBubbleTimeoutRef = useRef<NodeJS.Timeout | undefined>();
  const entityBubbleTimeoutRef = useRef<NodeJS.Timeout | undefined>();
  const sourceBubbleTimeoutRef = useRef<NodeJS.Timeout | undefined>();

  const [isTypingEffect, setTypingEffect] = useState<boolean>(false);
  const [processedAnswer, setProcessedAnswer] = useState<string>();
  const [hasShowMoreOrLess, setHasShowMoreOrLess] = useState(false);
  const [isShowingMore, setShowingMore] = useState(false);

  const typingEffectTimerRef = useRef<NodeJS.Timeout>();
  const endOfContentRef = useRef<HTMLDivElement>(null);

  const { width: rootWidth, ref: rootRef } = useResizeDetector({
    refreshOptions: { trailing: true },
  });

  const determineOpenGlossaryBubble = (rect: DOMRect, phrase: string) => {
    if (glossaryBubbleTimeoutRef.current) {
      clearTimeout(glossaryBubbleTimeoutRef.current);
      glossaryBubbleTimeoutRef.current = undefined;
    }

    const timeoutRef = setTimeout(() => {
      openGlossaryBubble({
        originX: rect.left + (rect.width / 2),
        originY: rect.top,
        phrase,
      });

      glossaryBubbleTimeoutRef.current = undefined;
    }, GLOSSARY_BUBBLE_TIMEOUT);

    glossaryBubbleTimeoutRef.current = timeoutRef;
  };

  const determineOpenEntityBubble = (rect: DOMRect, entityName: string, entityType: string) => {
    if (entityBubbleTimeoutRef.current) {
      clearTimeout(entityBubbleTimeoutRef.current);
      entityBubbleTimeoutRef.current = undefined;
    }

    const timeoutRef = setTimeout(() => {
      openEntityBubble({
        originX: rect.left + (rect.width / 2),
        originY: rect.top,
        entityName: decodeURI(entityName),
        entityType,
      });

      entityBubbleTimeoutRef.current = undefined;
    }, ENTITY_BUBBLE_TIMEOUT);

    entityBubbleTimeoutRef.current = timeoutRef;
  };

  const determineOpenSourceBubble = (
    rect: DOMRect,
    sourceIndex: number,
  ) => {
    if (sourceBubbleTimeoutRef.current) {
      clearTimeout(sourceBubbleTimeoutRef.current);
      sourceBubbleTimeoutRef.current = undefined;
    }

    const timeoutRef = setTimeout(() => {
      const sourceInfo = answerSources?.find((s) => s.index === sourceIndex);
      if (!sourceInfo) {
        return;
      }

      openSourceBubble({
        id: answerViewId || '',
        originX: rect.left + (rect.width / 2),
        originY: rect.top,
        sourceIndex,
        sourceInfo,
      });

      sourceBubbleTimeoutRef.current = undefined;
    }, SOURCE_BUBBLE_TIMEOUT);

    sourceBubbleTimeoutRef.current = timeoutRef;
  };

  const cancelEntityBubble = () => {
    if (entityBubbleTimeoutRef.current) {
      clearTimeout(entityBubbleTimeoutRef.current);
      entityBubbleTimeoutRef.current = undefined;
    }
  };

  const cancelGlossaryBubble = () => {
    return;
    if (glossaryBubbleTimeoutRef.current) {
      clearTimeout(glossaryBubbleTimeoutRef.current);
      glossaryBubbleTimeoutRef.current = undefined;
    }
  };

  const cancelSourceBubble = () => {
    if (sourceBubbleTimeoutRef.current) {
      clearTimeout(sourceBubbleTimeoutRef.current);
      sourceBubbleTimeoutRef.current = undefined;
    }
  };

  const removeSources = (text: string) => {
    const startIndexOfSource = text.indexOf('Sources:\n');
    if (startIndexOfSource >= 0) {
      return text.substring(0, startIndexOfSource);
    }

    return text;
  };

  const onQuickChartCommand = (
    command: string,
    chartType: 'inline' | 'attached',
    chartIndex: number,
    chartSubIndex: number,
    args: unknown,
  ) => {
    if (chatIndex === undefined || chatIndex === null) {
      return;
    }

    switch (command) {
      case 'duplicate':
        duplicateChatItemChart({
          chatItemIndex: chatIndex,
          chartIndex,
          chartSubIndex,
          chartType,
        });
        break;
      case 'update': {
        const prevSettings = inlineCharts?.[chartIndex]?.[chartSubIndex];
        const currentSettings = args as QuickChartUpdateInfo;
        const chartModifyInfo = {
          ...prevSettings,
          category: (
            prevSettings?.selectedCategorialCol === currentSettings.selectedCategorialCol
          ) ? undefined : currentSettings.selectedCategorialCol,
          xValue: (
            prevSettings?.selectedTextCol === currentSettings.selectedTextCol
          ) ? undefined : currentSettings.selectedTextCol,
          yValue: (
            prevSettings?.selectedNumericCol === currentSettings.selectedNumericCol
          ) ? undefined : currentSettings.selectedNumericCol,
          graphType: (
            prevSettings?.graphType === currentSettings.graphType
          ) ? undefined : currentSettings.graphType,
          text: currentSettings.text || undefined,
        } as QuickChartUpdateInfo;

        requestUpdateChatItemChart({
          chatItemIndex: chatIndex,
          chartIndex,
          chartSubIndex,
          chartType,
          chartModifyInfo,
        });
        break;
      }
      case 'remove':
        removeChatItemChart({
          chatItemIndex: chatIndex,
          chartIndex,
          chartSubIndex,
          chartType,
        });
        break;
      case 'undo':
        undoChatItemChartChange({
          chatItemIndex: chatIndex,
          chartIndex,
          chartSubIndex,
          chartType,
        });
        break;
      default:
        break;
    }
  };

  const toggleShowMoreStep = () => {
    setShowingMore(!isShowingMore);
  };

  const applyFullAnswer = () => {
    const answer = refineTableInAnswer(answerMarkdown);
    const latexProcessed = preprocessLaTeX(answer, relationEntities);

    const highlightedSourcesAns = getHighlightedSourceMarkdown(
      latexProcessed,
      answerSources,
      isProcessing,
    );

    const highlightedGlossariesAns = getGlossaryLinkMarkdown(
      highlightedSourcesAns,
      highlights || [],
    );

    const highlightedEntitiesAns = getEntityLinkMarkdown(
      highlightedGlossariesAns,
      relationEntities || [],
    );

    const inlineChartAns = getInlineChartMarkdown(highlightedEntitiesAns);
    const finalAnswer = omitEmbededSources ? removeSources(inlineChartAns) : inlineChartAns;

    setProcessedAnswer(finalAnswer);
  };

  const showTableGraph = (
    chatItemIndex: number,
    tableIndex: number,
    tableMarkdown: string,
  ) => {
    requestTableToChart({
      chatItemIndex,
      chartIndex: tableIndex,
      tableMarkdown,
    });
  };

  useEffect(() => {
    if (chatState === 'ends') {
      typingEffectTimerRef.current = setTimeout(() => {
        setTypingEffect(false);
        applyFullAnswer();
      }, 3000);
    }
  }, [chatState]);

  useEffect(() => {
    if (isProcessing === false) {
      setTypingEffect(false);
    }
  }, [isProcessing]);

  useEffect(() => {
    applyFullAnswer();
  }, [answerMarkdown]);

  useEffect(() => {
    const rootElem = rootRef?.current as HTMLDivElement;
    const container = rootElem?.firstElementChild as HTMLDivElement;
    const gptMarkdown = container?.firstElementChild as HTMLDivElement;
    const shouldHaveShowMore = gptMarkdown?.offsetHeight < gptMarkdown?.scrollHeight;

    setHasShowMoreOrLess(shouldHaveShowMore);
  }, [rootRef?.current]);

  const chartUpdateRequestArgs = chatItemChartUpdateRequest.extra as Record<string, number>;

  if (!processedAnswer) {
    return (
      <div />
    );
  }

  const uniqueTableSources = Array.from(new Set(tableSources));
  const isMultipleSources = uniqueTableSources.length > 1;

  const tableNodes: object[] = [];
  const tableMarkdowns = answerMarkdown
    ? extractTableMarkdown(answerMarkdown)
    : [];

  return (
    <div ref={rootRef} className={classes.root} dir="auto">
      <AnswerContext.Provider
        value={{
          processedAnswer,
          isStepContent: stepContent,
          chatState,
        }}
      >
        <MemoGptMarkdown
          children={processedAnswer}
          remarkPlugins={[remarkGfm, remarkMath]}
          rehypePlugins={[rehypeKatex]}
          width={rootWidth}
          inlineCharts={inlineCharts}
          chartNetworkState={chatItemChartUpdateRequest}
          relationEntities={relationEntities}
          className={
            classNames(
              !stepContent
                ? classes.markdown
                : classes.stepMarkdown,
              isShowingMore && 'showFull',
              tableToChartRequest.isRequesting && 'requestingChart',
            )
          }
          components={{
            code: CodeComponent,
            table: (props) => {
              const dataRows = getTableRows(props);
              const dataHeaders = getTableHeaders(props);

              if (props.node && !tableNodes.includes(props.node)) {
                tableNodes.push(props.node);
              }

              const tableIndex = tableNodes.length - 1;
              const tableMarkdown = tableMarkdowns[tableIndex];

              return rootWidth ? (
                <Box marginBottom={1} width={rootWidth}>
                  {isTypingEffect || (chatState === 'streaming' && !stepContent) ? (
                    <div
                      style={{
                        marginTop: 8,
                        maxWidth: rootWidth,
                        overflow: 'hidden',
                      }}
                    >
                      <table
                        {...props}
                      />
                    </div>
                  ) : (
                    <DataTable
                      dataHeaders={dataHeaders}
                      dataRows={dataRows}
                      width={rootWidth}
                      customTools={[{
                        key: 'showGraph',
                        tooltip: 'Show Graph',
                        tableIndex,
                        icon: <ShowGraphIcon />,
                        onClick: () => {
                          if (!chatIndex) {
                            return;
                          }

                          showTableGraph(chatIndex, tableIndex, tableMarkdown);
                        },
                      }]}
                    />
                  )}
                  {tableSources?.at(0) && !isMultipleSources && (
                    <Box marginBottom={1}>
                      <small className={classes.tableSourceTitle}>
                        Source: {tableSources?.at(0)}
                      </small>
                    </Box>
                  )}
                </Box>
              ) : (
                <div />
              );
            },
            a: (props) => {
              if (props?.href?.startsWith('/glossary#')) {
                return (
                  <Link
                    type="button"
                    className={classes.answerHighlight}
                    onMouseUp={() => {
                      return;
                      toggleGptChat({ isGptChat: false });
                      setSearchKeyword({
                        keyword: `"${props?.children as string}"`,
                      });
                      cancelGlossaryBubble();
                      closeSourceBubble();
                      closeEntityBubble();
                    }}
                    onMouseEnter={(event) => {
                      return;
                      const rect = event.currentTarget.getBoundingClientRect();
                      determineOpenGlossaryBubble(rect, props?.children as string);
                    }}
                    onMouseLeave={() => cancelGlossaryBubble()}
                  >
                    {props?.children}
                  </Link>
                );
              }

              if (props?.href?.startsWith('/entity-')) {
                const suffixLength = '/entity-'.length;
                const sharpIndex = props.href.indexOf('#');
                const entityType = props.href.substring(suffixLength, sharpIndex);
                const entityName = props.href.substring(sharpIndex + 1);

                return (
                  <Link
                    type="button"
                    className={classNames(
                      classes.entityHighlight,
                      entityType,
                    )}
                    onMouseUp={() => {
                      cancelGlossaryBubble();
                      closeSourceBubble();
                      closeEntityBubble();
                    }}
                    onMouseEnter={(event) => {
                      const rect = event.currentTarget.getBoundingClientRect();
                      determineOpenEntityBubble(rect, entityName, entityType);
                    }}
                    onMouseLeave={() => cancelEntityBubble()}
                  >
                    {props?.children}
                  </Link>
                );
              }

              if (props?.href?.startsWith('/inlineChart#')) {
                const chartIndex = Number(props.href.substring('/inlineChart#'.length));
                const chartInfos = inlineCharts?.[chartIndex];

                const chartRequestExtra = tableToChartRequest.extra as Record<string, unknown>;
                if (tableToChartRequest.isRequesting
                  && tableToChartRequest.index === chatIndex
                  && chartRequestExtra?.chartIndex === chartIndex
                  && !chartRequestExtra?.isAttachment
                ) {
                  return (
                    <span className={classes.requestingChart}>
                      <CircularProgress color="inherit" size={40} />
                    </span>
                  );
                }

                return (
                  <>
                    {(chartInfos || []).map((chartInfo, chartSubIndex) => (
                      chartInfo && chartInfo.graphId && rootWidth ? (
                        <span key={chartSubIndex} className={classes.chartBox}>
                          <QuickChart
                            key={chartSubIndex}
                            chartInfo={chartInfo}
                            hidden={chatState === 'streaming' || isTypingEffect}
                            colorSet="tertiary"
                            onCommand={(command, args) => {
                              onQuickChartCommand(
                                command,
                                'inline',
                                chartIndex,
                                chartSubIndex,
                                args,
                              );
                            }}
                            isUpdating={
                              chatItemChartUpdateRequest.isRequesting
                              && chatItemChartUpdateRequest.index === chatIndex
                              && chartUpdateRequestArgs.chartIndex === chartIndex
                              && chartUpdateRequestArgs.chartSubIndex === chartSubIndex
                            }
                            isUndoable={!!chartInfo?.prevChartInfo}
                            chartWidth={rootWidth}
                          />
                        </span>
                      ) : (
                        <span key={chartSubIndex} />
                      )
                    ))}
                  </>
                );
              }

              if (props?.href?.startsWith('/sources#')) {
                return (
                  <Link
                    type="button"
                    className={classes.sourceHighlight}
                    style={{ marginBottom: 5 }}
                    onMouseEnter={(event) => {
                      const hrefParts = (props?.href || '').split('#');
                      const index = hrefParts.length > 1 ? Number(hrefParts[1]) : 0;
                      const rect = event.currentTarget.getBoundingClientRect();

                      determineOpenSourceBubble(rect, index);
                    }}
                    onMouseLeave={() => cancelSourceBubble()}
                  >
                    {props?.children}
                  </Link>
                );
              }

              return (
                <a href={props.href}>{props.children}</a>
              );
            },
            img: (props) => (
              <>
                {props.src && rootWidth && (
                  <ImageLoader src={props.src} maxWidth={rootWidth} maxHeight={200} />
                )}
              </>
            ),
          }}
        />
      </AnswerContext.Provider>

      <Portal>
        <CalloutBubble
          colorSet="source"
          open={sourceBubble?.isOpen && sourceBubble.id === answerViewId}
          originX={sourceBubble?.originX}
          originY={sourceBubble?.originY}
          onClose={() => closeSourceBubble()}
        >
          <Box display="flex" flexDirection="column">
            <Box>
              <Box display="flex" justifyContent="flex-start">
                <Typography
                  variant="body2"
                  className={classNames(classes.sourceHighlight, 'noEvent')}
                >
                  {sourceBubble?.sourceIndex}
                </Typography>
                <Link
                  variant="body2"
                  className={classes.sourceBubbleTitle}
                  href={sourceBubble?.sourceInfo?.metadata?.source}
                  target="_blank"
                >
                  {(
                    sourceBubble?.sourceInfo?.metadata?.title
                    || sourceBubble?.sourceInfo?.metadata?.source
                  )}
                </Link>
              </Box>
              <Box className={classes.sourceBubbleContent}>
                {sourceBubble?.sourceInfo?.table ? (
                  <div style={{ fontSize: '0.2rem' }}>
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm, remarkMath]}
                      rehypePlugins={[rehypeKatex]}
                      children={translateSourceTableToMarkdown(sourceBubble?.sourceInfo?.table, 10)}
                    />
                  </div>
                ) : (
                  <ContentView
                    content={(
                      sourceBubble?.sourceInfo?.pageContent
                    )}
                  />
                )}
              </Box>
              {answerMode === 'search' && (
                <Box marginTop={1} marginLeft={2.75} display="flex" alignItems="center">
                  {sourceBubble?.sourceInfo?.favIconUrl && (
                    <img
                      src={sourceBubble?.sourceInfo?.favIconUrl}
                      className={classes.sourceBubbleFavIcon}
                    />
                  )}
                  <Typography variant="body2" className={classes.sourceBubbleDomain}>
                    {sourceBubble?.sourceInfo?.domain}
                  </Typography>
                </Box>
              )}
              <Box className={classes.showMeButtonContainer}>
                <Button
                  classes={commonCss.buttons.roundButton}
                  className={classes.showMeButton}
                  size="small"
                  onClick={() => {
                    closeSourceBubble();

                    if (sourceBubble?.sourceIndex === 0 && chatIndex !== undefined) {
                      toggleChatItemTable({
                        chatItemIndex: chatIndex,
                        isShowing: true,
                      });

                      setTimeout(() => {
                        const elems = document.getElementsByClassName(`sourceMetaTableView-${chatIndex}`);
                        const anchor = elems.item(0);
                        if (anchor) {
                          anchor.scrollIntoView({ behavior: 'smooth' });
                        }
                      }, 300);

                      return;
                    }

                    toggleSourceCitation({
                      isOpen: true,
                      url: sourceBubble?.sourceInfo?.metadata?.source,
                      phrase: sourceBubble?.sourceInfo?.pageContent,
                      title: sourceBubble?.sourceInfo?.metadata.title,
                      sourceId: sourceBubble?.sourceInfo?.sourceId,
                      pageNumber: sourceBubble?.sourceInfo?.sourcePageNo,
                      timestamp: new Date(),
                    });

                    if (sourceBubble?.sourceInfo?.metadata?.source) {
                      setSelectedSideBarTab({ tabName: 'browser' });
                    }

                    if (sourceBubble?.sourceInfo?.sourceId) {
                      setSelectedSideBarTab({ tabName: 'pdf' });
                    }
                  }}
                >
                  Show Me
                  <Box display="flex" marginLeft={0.5} alignItems="center">
                    <ShowMeIcon />
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
        </CalloutBubble>
      </Portal>

      {stepContent && hasShowMoreOrLess && (
        <Link
          type="button"
          className={classes.showMoreStepContent}
          onClick={() => toggleShowMoreStep()}
        >
          {!isShowingMore ? 'Show More' : 'Show Less'}
        </Link>
      )}

      <div ref={endOfContentRef} />
    </div>
  );
};

export default GptAnswerView;
